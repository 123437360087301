<div class="info-container">
<div (click)="closeBox()" class="logo">
<img src="{{drilldownService.boxDataLogo}}">
</div>
<div [hidden]="!showFullscreenPic" class="fullscreen-image">
<img src="{{currentFullscreenPic}}">
</div>
<div class="inner">
<div (click)="boxContentDisplayCtrl(&quot;intro&quot;)" [hidden]="getBoxDataDisplay() !== &#39;contact&#39; || !drilldownService.boxData" class="back-container">
<i class="fas fa-chevron-left"></i>
<span>BACK</span>
</div>
<!-- /Into Box Logo -->
<div class="inner-header">
<!-- /Normal Title -->
<!-- /%h1{'[hidden]' => "getBoxDataDisplay() == 'contact'"} {{boxDataTitle}} -->
<!-- /Contact Form Title -->
<!-- %h1.contact-title-main{'[hidden]' => "getBoxDataDisplay() !== 'contact'"} Contact -->
<!-- %h1.title-main{'[hidden]' => "getBoxDataDisplay() !== 'document'"} Documents -->
<!-- %h1.title-main{'[hidden]' => "getBoxDataDisplay() !== 'video'"} Videos -->
<!-- /Introduction -->
<div [hidden]="getBoxDataDisplay() != &#39;intro&#39;" [ngClass]="{&#39;display-text-box&#39;: getBoxDataDisplay() == &#39;intro&#39;}" class="text-box text">
<div class="text-info">
<h2>{{drilldownService.boxDataTitle}}</h2>
<pre>{{drilldownService.boxDataDescription}}</pre>
</div>
<div *ngIf="drilldownService.boxDataImages.length == 1" [ngClass]="{&#39;display-text-box&#39;: getBoxDataDisplay() == &#39;intro&#39;}" class="image-container-feature">
<div [ngStyle]="{&#39;background-image&#39;: &#39;url(&#39; + drilldownService.boxDataImage + &#39;)&#39;}" class="image-container-feature-item"></div>
</div>
<ngb-carousel *ngIf="drilldownService.boxDataImages.length &gt; 1" class="image-container-feature" pauseOnFocue="true" pauseOnHover="true">
<ng-template *ngFor="let image of drilldownService.boxDataImages" class="template" ngbSlide="">
<div class="picsum-img-wrapper">
<img [src]="apiURL + image.images.url">
</div>
</ng-template>
</ngb-carousel>
</div>
<!-- /Video -->
<div [hidden]="getBoxDataDisplay() != &#39;video&#39;" [ngClass]="{&#39;display-text-box&#39;: getBoxDataDisplay() == &#39;video&#39;}" class="text-box central-text-box video">
<!-- /Video Player -->
<div class="title">
<span>{{drilldownService.boxDataTitle}}</span>
</div>
<div class="bottom">
<div class="video-container">
<iframe *ngIf="getBoxDataDisplay() == &quot;video&quot;" [hidden]="!drilldownService.boxDataVideoURL" [src]=" drilldownService.boxDataVideoURL | embedding" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" class="video-container" frameborder="0"></iframe>
<span [hidden]="drilldownService.boxDataVideoURL">Video coming soon.</span>
</div>
<!-- /For when there is more than one video -->
<div class="list">
<span class="title">Video</span>
<div class="video-list-container">
<div (click)="playVideo(video[&#39;videoURL&#39;])" *ngFor="let video of drilldownService.boxDataVideo | region: accessoriesService.Filter: true" [ngClass]="{&#39;video-playing&#39;: drilldownService.boxDataVideoURL == video[&#39;videoURL&#39;]}" class="other-video-container">
<div *ngIf="video.backdrop" class="thumbnail">
<img src="{{apiURL + video.backdrop.url}}">
</div>
<span class="closed-video">{{video['title']}}</span>
</div>
</div>
</div>
</div>
</div>
<!-- /Document -->
<div [hidden]="getBoxDataDisplay() != &#39;document&#39;" [ngClass]="{&#39;display-text-box&#39;: getBoxDataDisplay() == &#39;document&#39;}" class="text-box central-text-box pdf">
<div class="title">
<span>{{drilldownService.boxDataTitle}}</span>
</div>
<div class="bottom">
<div [ngClass]="{&#39;display-none&#39;: !drilldownService.boxDataPDF}" class="video-container desktop pdf-viewer">
<embed [src]="drilldownService.boxDataSelectedPDF + &quot;#toolbar=0&quot; | embedding" type="application/pdf">
</div>
<div class="list">
<span class="title">Documents</span>
<div class="video-list-container desktop">
<div (click)="boxDocumentLauncher(sheet)" *ngFor="let sheet of drilldownService.boxDataPDF | region: accessoriesService.Filter: false" [ngClass]="{&#39;doc-displaying&#39;: drilldownService.currentPdfUrl == sheet[&#39;file&#39;][&#39;url&#39;]}" class="other-video-container">
<div class="thumbnail">
<img *ngIf="sheet.thumbnail" src="{{apiURL + sheet.thumbnail.url}}">
<img *ngIf="!sheet.thumbnail" src="assets/icons/PDF_file_icon.svg">
</div>
<span class="closed-video">{{sheet['title']}}</span>
</div>
</div>
</div>
<div class="video-list-container mobile">
<div (click)="openDocument(sheet)" *ngFor="let sheet of drilldownService.boxDataPDF | region: accessoriesService.Filter: false" [ngClass]="{&#39;doc-displaying&#39;: drilldownService.currentPdfUrl == sheet[&#39;file&#39;][&#39;url&#39;]}" class="other-video-container">
<div class="thumbnail">
<img *ngIf="sheet.thumbnail" src="{{apiURL + sheet.thumbnail.url}}">
<img *ngIf="!sheet.thumbnail" src="assets/icons/PDF_file_icon.svg">
</div>
<span class="closed-video">{{sheet['title']}}</span>
</div>
</div>
</div>
<span [hidden]="drilldownService.boxDataPDF" class="coming-soon">Document coming soon.</span>
</div>
</div>
<!-- /Info box menu -->
<div [hidden]="getBoxDataDisplay() == &#39;contact&#39;" class="btn-container">
<div (click)="boxContentDisplayCtrl(&quot;intro&quot;)" [ngClass]="{&#39;btn-actived&#39;: getBoxDataDisplay() == &#39;intro&#39;}" class="btn btn-bg0">
<img src="../assets/icons/Group 10.png">
</div>
<div (click)="boxContentDisplayCtrl(&quot;document&quot;)" *ngIf="drilldownService.boxDataPDF.length &gt; 0" [ngClass]="{&#39;btn-actived&#39;: getBoxDataDisplay() == &#39;document&#39;}" class="btn btn-bg2">
<img src="../assets/icons/Group 7.png">
</div>
<div (click)="boxContentDisplayCtrl(&quot;video&quot;)" *ngIf="drilldownService.boxDataVideo.length &gt; 0" [ngClass]="{&#39;btn-actived&#39;: getBoxDataDisplay() == &#39;video&#39;}" class="btn btn-bg1">
<img src="../assets/icons/noun-video-1591623.png">
</div>
<div (click)="closeBox()" [ngClass]="{&#39;btn-actived&#39;: getBoxDataDisplay() == &#39;contact&#39;}" class="btn btn-bg3">
<img src="../assets/icons/Group 13.png">
</div>
</div>
</div>
</div>
