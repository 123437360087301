import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'menu'
})
export class MenuPipe implements PipeTransform {

  transform(value: Array<any>, menu: boolean): Array<any> {
    return value.filter(x => x.menuHotspot == menu);
  }

}
