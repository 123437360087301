<div class="accessories-container">
<div (click)="close()" class="close">
<img src="assets/icons/noun-close-988406-FFFFFF.svg">
</div>
<div class="header">
<div class="left">
<div class="logo">
<img *ngIf="accessoriesService.Filter == &quot;ROW&quot;" src="assets/images/Evoqua_Logo_CMYK_Horz (1) (1).png">
<img *ngIf="accessoriesService.Filter != &quot;ROW&quot;" src="assets/images/jpg_neptune-benson-logo_color (1).jpg">
</div>
<div class="title">Accessories</div>
</div>
<div class="right"></div>
</div>
<div class="bottom">
<div class="main">
<div (click)="openHotspot(hotspot)" *ngFor="let hotspot of (hotspots | accessory: true) | filter: accessoriesService.Filter" class="accessory">
<div class="thumbnail">
<img *ngIf="hotspot.hotspotImage" src="{{apiUrl + hotspot.hotspotImage.url}}">
</div>
<div [ngClass]="{&quot;selected&quot;: hotspot.title == title}" class="title">
<span>{{hotspot.title}}</span>
</div>
</div>
</div>
<div class="info-text">
<span class="title">{{title}}</span>
<span>{{text}}</span>
<div (click)="openLink()" *ngIf="title != &quot;Accessories&quot;" class="btn">
<span>Learn More</span>
</div>
</div>
</div>
</div>
