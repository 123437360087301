import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { AccessoriesService } from '../service/accessories.service';

@Component({
  selector: 'app-accessories',
  templateUrl: './accessories.component.html',
  styleUrls: ['./accessories.component.scss']
})
export class AccessoriesComponent implements OnInit {

  hotspots: any = null;
  @Input() logo: string;

  apiUrl: string = this.api.getAPIURL();

  title = "Accessories";
  text = "Please select an accessory to start."

  constructor(private api: ApiService,
    public accessoriesService: AccessoriesService) { }

  ngOnInit(): void {
    this.hotspots = this.accessoriesService.Hotspots;
  }

  openHotspot(hotspot){
    this.title = hotspot.title;
    this.text = hotspot.description
  }

  close(){
    this.accessoriesService.accessoriesOpen = false;
  }

  openLink(){
    let hotspot = this.accessoriesService.Hotspots.find(x => x.description == this.text);
    window.open(hotspot.externalURL, '_blank')
  }
}
