import { Injectable } from '@angular/core';
import * as nodemailer from "nodemailer";
import * as AWS from 'aws-sdk';
import { stringify } from '@angular/compiler/src/util';
import { provideRoutes } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  emailID       = '';
  emailKey      = '';
  emailRegion   = '';
  emailConfig   = null;
  contactInterest = [];

  constructor() { }

  initEmail(): void {
    this.emailID      = 'AKIA5OT7KMPAUZGHBIU2'; //accessKeyId
    this.emailKey     = 'PexUYwx9kv+ACqfG2CKByyq8xM9dqiG7ktZxbfq9'; //secretAccessKey
    this.emailRegion  = 'us-east-2'; //region
    this.emailConfig  = {apiVersion: '2010-12-01', accessKeyId: this.emailID, secretAccessKey: this.emailKey, region: this.emailRegion};
  }


  sendMail(contactFirstName, contactSurname, contactCompany, contactEmail, contactPhone, contactMessage, products) {
    let emailList = [];

    //Demo email address
    emailList.push('jwatt@animmersion.co.uk')

    let contactInterests: string = "Is interested in these products: ";


    products.forEach(element => {
      contactInterests = contactInterests + element['name'] + ", ";
    });



    var params = {
      Destination: { /* required */
        ToAddresses: emailList,
      },
      Message: { /* required */
        Body: { /* required */
          Html: {
          Charset: "UTF-8",
          Data: "The following visitor has submitted a message via Podium. <p>" +
                  "First Name: " + contactFirstName + "<br/>" +
                  "Last Name: " + contactSurname + "<br/>" +
                  "Company: " + contactCompany + "<br/>" +
                  "Email: " + contactEmail + "<br/>" +
                  "Phone: " + contactPhone + "<br/>" +
                  "Message: " + contactMessage + "</p>" +
                  contactInterests +
                  "<p><i>Please do not reply this email.</i></p>"
          }
        },
        Subject: {
          Charset: 'UTF-8',
          Data: 'Email from Normet'
        }
        },
      Source: 'noreply@email.the-podium.com', /* required */
      ReplyToAddresses: [
        'normat@zen.the-podium.com'
      ],
    };

    new AWS.SES(this.emailConfig).sendEmail(params).promise().then((res) => {
      window.alert('Thank you. Your message has been sent.');
      console.log(res);
    })

  }
}
