<div class="login-container">
  <div class="logo">
    <img src="../assets/images/Animmersion-logo-no-txt.png" alt="">
  </div>
  <div class="form">
    <h1>This Experience Is Protected</h1>
    <span>If you do not know the password, please contact your account manager.</span>
    <input type="password" name="password" id="password" [(ngModel)]="user.password" (keydown.enter)="onKeydown($event)">
    <small class="error" *ngIf="!passwordMatch">
      The password does not match our records.
    </small>
    <div class="btn" (click)="submit()">
      <span>Submit</span>
    </div>
  </div>
</div>
