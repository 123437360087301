import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from '../api.service';
import { HotspotService } from '../hotspot.service';

@Component({
  selector: 'app-hotspot',
  templateUrl: './hotspot.component.html',
  styleUrls: ['./hotspot.component.scss']
})
export class HotspotComponent implements OnInit {
  constructor(private ApiService: ApiService, public hotspotService: HotspotService) { }
  @Input() dataIn: object;

  @Input() menuHotspot?: boolean;

  pinTitle:string    = null;
  pinNumber:string   = null;
  filter: string     = null;

  left = false;
  top = false;

  menu: boolean;

  ngOnInit(): void {
    this.initPin();
  }

  initPin(): void {
    if(this.dataIn != null){
      this.pinTitle = this.dataIn['title'];
      this.pinNumber = this.dataIn['hotspotAlias'];
      this.filter = this.dataIn['filters'];
      this.menu = this.dataIn['menuHotspot']
      if(this.dataIn['left'] == null){
        this.left = false;
      }else{
        this.left = this.dataIn['left']
      }
      if(this.dataIn['top'] == null){
        this.top = false;
      }else{
        this.top = this.dataIn['top'];
      }
    }else{
      this.left = false;
      this.top = false;
      this.pinTitle = "Accessories";
      this.filter = "accessories"
    }
  }
}
