<div [hidden]="!popupVideoPlay" class="popup-play-container">
<iframe [src]=" popupVideoURL | embedding" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen="true" class="video" frameborder="0"></iframe>
<i (click)="playPopupVideo()" class="fas fa-times video-player-cross"></i>
</div>
<div class="container">
<app-accessories *ngIf="accessoriesService.accessoriesOpen" [@inOutAnimation]="" [logo]="boxDataLogo"></app-accessories>
<div *ngIf="landingOpen" class="landing">
<div class="landing-container">
<div class="landing-logo">
<img class="unselectable" src="{{boxDataLogo}}">
</div>
<div class="text">
<span class="bold">Select your country preference</span>
<span>Please update your country preference to view content specific to your location</span>
</div>
<div class="selection">
<div (click)="selectFilter(&quot;EMEA&quot;)" class="select">
<div class="img">
<img src="assets/icons/Image 2.png">
</div>
<span>United States</span>
</div>
<div class="line"></div>
<div (click)="selectFilter(&quot;ROW&quot;)" class="select">
<div class="img">
<img src="assets/icons/noun-earth-11066001.png">
</div>
<span>The Rest of the World</span>
</div>
</div>
</div>
</div>
<div class="filter-container">
<div (click)="openContact()" class="icon">
<img src="assets/icons/noun_Email_1208045.png">
</div>
<div (click)="landingOpen = true" class="icon">
<img *ngIf="filterSelected == &quot;ROW&quot;" src="assets/icons/noun-earth-1106600.png">
<img *ngIf="filterSelected == &quot;EMEA&quot;" src="assets/icons/Image 2.png">
</div>
</div>
<div class="logo">
<img class="unselectable" src="assets/icons/Evoqua_Logo_White_Horz.png">
<!-- /Mobile Filters -->
<!-- /.filter.mobile -->
<!-- /  %i.fas.fa-bars.mobile-filter-btn{'(click)' => 'mobileMenuDisplay = !mobileMenuDisplay', '[ngClass]' => '{"fa-times" : mobileMenuDisplay}'} -->
<!-- /Desktop Filters -->
<!-- /.filter.mobile-filter{'[ngClass]' => "{'active-mobile-filter': mobileMenuDisplay}"} -->
<!-- /  .filter-logo-container{'*ngFor' => 'let filter of boxDataFilter', '(click)' => "filterHandler(filter['filterName'],2)", '[ngClass]' => '{"grey-filter": filterDisplay(filter["filterName"],2), "drilldown-filter": getDrillDownOpen()}', '[ngStyle]' => "{'background-color': filter['filterColour']}"} -->
<!-- /    %img{'*ngIf' => 'filter.filterName == "Services"', src: '../assets/icons/services.png'} -->
<!-- /    %img{'*ngIf' => 'filter.filterName == "Structural"', src: '../assets/icons/structural.png'} -->
<!-- /    %img{'*ngIf' => 'filter.filterName == "Explosives"', src: '../assets/icons/explosives.png'} -->
<!-- /    %img.water{'*ngIf' => 'filter.filterName == "Water Control"', src: '../assets/icons/water.png'} -->
<!-- /    %span.unselectable {{filter['filterName']}} -->
<!-- /  .filter-contact{'(click)' => 'contactCtrl()', '[ngClass]' => '{"drilldown-filter": getDrillDownOpen()}'} -->
<!-- /    %img{src: '../assets/icons/noun_Mail_1580478.png'} -->
<!-- /    %span.unselectable Contact -->
</div>
<div (click)="resetMap(&quot;&quot;)" *ngIf="mapData.id != &quot;1&quot;" class="top-level-container">
<img src="../assets/icons/noun_Stairs_77222.png">
<span>Back to the ground level</span>
</div>
<!-- /Landing page Map -->
<div class="map-container">
<div [ngStyle]="{&#39;background-image&#39;: &#39;url(&#39; + mapBg + &#39;)&#39;}" class="map">
<iframe [hidden]="mapVid" [src]=" mapVid | embedding" allow="autoplay; fullscreen" allowfullscreen="" class="map-vid-container" frameborder="0"></iframe>
<!-- /Hot spots -->
<div class="hotspot-container">
<div (click)="boxDisplayCtrl(pin)" *ngFor="let pin of (((hotspots | menu: false) | filter: accessoriesService.Filter) | accessory: false)" [ngStyle]="{&quot;width&quot;: pin.areaCoordsWidth + &quot;%&quot;, &quot;height&quot;: pin.areaCoordsHeight + &quot;%&quot;, &quot;top&quot;: pin.areaCoordsPositionY + &quot;%&quot;, &quot;left&quot;: pin.areaCoordsPositionX + &quot;%&quot;}" class="object"></div>
<div (click)="subMenuChange(pin[&#39;id&#39;])" *ngFor="let pin of menuHotspots" [ngStyle]="{&quot;width&quot;: pin.areaCoordsWidth + &quot;%&quot;, &quot;height&quot;: pin.areaCoordsHeight + &quot;%&quot;, &quot;top&quot;: pin.areaCoordsPositionY + &quot;%&quot;, &quot;left&quot;: pin.areaCoordsPositionX + &quot;%&quot;}" class="object"></div>
<app-hotspot (click)="boxDisplayCtrl(pin)" *ngFor="let pin of (((hotspots | menu: false) | filter: accessoriesService.Filter) | accessory: false) | right: false" [dataIn]="pin" [hidden]="hotspotDisplay(pin)" [ngStyle]="{&quot;left&quot;: pin.pinPositionX + &quot;%&quot;, &quot;top&quot;: pin.pinPositionY + &quot;%&quot;}" class="hotspot-style"></app-hotspot>
<app-hotspot (click)="boxDisplayCtrl(pin)" *ngFor="let pin of (((hotspots | menu: false) | filter: accessoriesService.Filter) | accessory: false) | right: true" [dataIn]="pin" [hidden]="hotspotDisplay(pin)" [ngStyle]="{&quot;right&quot;: pin.pinPositionX + &quot;%&quot;, &quot;top&quot;: pin.pinPositionY + &quot;%&quot;}" class="hotspot-style"></app-hotspot>
<!-- /%app-hotspot.hotspot-style{'*ngFor' => 'let map of boxDataMaps', '[ngStyle]' => '{"left": map.pinPositionX + "%", "top": map.pinPositionY + "%"}', '[dataIn]' => 'map', '(click)' => 'resetMap(map.title)'} -->
<app-hotspot (click)="openAccessories()" [dataIn]="null" [ngStyle]="{&quot;right&quot;: &quot;72&quot; + &quot;%&quot;, &quot;top&quot;: &quot;14&quot; + &quot;%&quot;}" class="hotspot-style"></app-hotspot>
<div *ngFor="let pin of menuHotspots | right: false" [ngStyle]="{&quot;left&quot;: pin.pinPositionX + &quot;%&quot;, &quot;top&quot;: pin.pinPositionY + &quot;%&quot;}" class="submenu-container hotspot-style">
<app-hotspot (click)="subMenuChange(pin[&#39;id&#39;])" [dataIn]="pin" [hidden]="hotspotDisplay(pin)" [ngStyle]="{&quot;position&quot;: &quot;unset&quot;}" class="hotspot-style"></app-hotspot>
<app-hotspot-menu [dataIn]="pin[&quot;hotspotList&quot;]" [hidden]="!subMenuShow.includes(pin[&quot;id&quot;])"></app-hotspot-menu>
</div>
<div *ngFor="let pin of menuHotspots | right: true" [ngStyle]="{&quot;right&quot;: pin.pinPositionX + &quot;%&quot;, &quot;top&quot;: pin.pinPositionY + &quot;%&quot;}" class="submenu-container hotspot-style">
<app-hotspot (click)="subMenuChange(pin[&#39;id&#39;])" [dataIn]="pin" [hidden]="hotspotDisplay(pin)" [ngStyle]="{&quot;position&quot;: &quot;unset&quot;}" class="hotspot-style"></app-hotspot>
<app-hotspot-menu [dataIn]="pin[&quot;hotspotList&quot;]" [hidden]="!subMenuShow.includes(pin[&quot;id&quot;])"></app-hotspot-menu>
</div>
</div>
</div>
</div>
<!-- /Into Box -->
<!-- .info-container-filter{'[hidden]' => '!getDrillDownOpen()'} -->
<!-- /%app-drilldown{'*ngFor' => 'let pin of hotspots','[hidden]' => "pin['title'] !== currentDrilldown || !getDrillDownOpen()", '[dataIn]' => 'pin'} -->
</div>
<!-- /Mobile swipe reminder -->
<div [hidden]="!showTutorial" class="mobile-tutorial-container">
<svg data-name="Swipe horizontal 1" id="Swipe-horizontal_1" viewbox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
<path class="hand-x" d="M139.93,113.56l-41.12-6.93V76.08a9.25,9.25,0,0,0-9.25-9.25h0a9.25,9.25,0,0,0-9.25,9.25v57.36L71,122.65c-3.61-3.61-8.44-3.89-13.08,0,0,0-7.24,5.84-3.83,9.25l34,34h42.63a9.25,9.25,0,0,0,9.07-7.43l6.82-34.09A9.28,9.28,0,0,0,139.93,113.56Z"></path>
<g class="swipe-horizontal">
<path class="line-horizontal" d="M70.85,42c19.69-12.46,37,0,37,0"></path>
<polyline class="arrow-left" points="76.6 46.01 68.37 43.43 68.38 43.41 70.96 35.18"></polyline>
<polyline class="arrow-right" points="100.21 44.66 108.43 42.08 108.43 42.06 105.85 33.84"></polyline>
</g>
</svg>
<span class="v2-type-sm-b tutorial-hint">Swipe Left / Right to view the map</span>
<div (click)="showTutorial = false" class="tutorial-btn hyper-link hover-reaction disable-select">
<span>Got it</span>
</div>
</div>
