import { IfStmt } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../api.service';
import { DrilldownService } from '../drilldown.service';
import {MailService} from '../mail.service';
import { AccessoriesService } from '../service/accessories.service';
import { LoadingService } from '../service/loading.service';

@Component({
  selector: 'app-drilldown',
  templateUrl: './drilldown.component.html',
  styleUrls: ['./drilldown.component.scss']
})
export class DrilldownComponent implements OnInit {

  @Input() dataIn: any;

  contactFirstName      = null;
  contactSurname        = null;
  contactEmail          = null;
  contactCompany        = null;
  contactMessage        = null;
  contactPhone          = null;
  contactInterest       = [false,false,false,false];
  contactGDPR           = false;

  boxData               = null
  boxDataTitle          = null;
  boxDataDescription    = null;
  boxDataPDF            = null;
  boxDataInterest       = null;
  boxDataImage          = './../assets/images/na-image.jpg';
  boxDataDisplay        = 'intro';
  boxDataVideo          = null;
  boxDataVideoURL       = null;
  boxDataSubtitle       = null;
  boxDataGallery        = null;
  boxDataSelectedPDF    = null;
  apiURL                = this.apiService.getAPIURL();

  boxContentDisplay     = false;

  showFullscreenPic     = false;
  currentFullscreenPic  = null;

  GDPRdisplayStatus     = false;
  recaptchaVerified     = false;

  contactFirstNameError = null;
  contactSurnameError = null;
  contactCompanyError = null;
  contactMessageError = null;
  contactPhoneError = null;
  contactEmailError = null;

  currentPdfUrl     = null;

  routeName: string = null;

  constructor(public drilldownService: DrilldownService,
    private apiService: ApiService,
    private mail: MailService,
    private route: ActivatedRoute,
    private router: Router,
    public accessoriesService: AccessoriesService,
    private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.loadingService.Loading = true;
    if(this.accessoriesService.checkFilter()){
      this.route.params.subscribe(async params => {
        this.routeName = params['id'];
        this.drilldownService.getDrilldownData(this.routeName);
        this.drilldownService.drilldownOpen = true;
      })
    }else{
      this.closeBox();
    }
  }

  closeBox(){
    this.loadingService.Loading = true;
    this.drilldownService.boxDataVideoURL = null;
    this.drilldownService.boxData = null;
    this.drilldownService.drilldownOpen = false;
    this.drilldownService.contactOpen = false;
    this.drilldownService.boxDataDisplay  = "intro";
    this.router.navigate(['dashboard'])
  }

  boxContentDisplayCtrl(sectionIn:string): void {
    this.drilldownService.setBoxDataDisplay(sectionIn);
    if(sectionIn == 'video'){
      if(this.boxDataVideo){
        if(this.boxDataVideo.length >= 0){
          this.boxDataVideoURL = this.boxDataVideo[0]['videoURL'];
        }
      }
    }else{
      this.boxDataVideoURL = null;
    }
  }

  showFullscreenPicture(pictureURL: string): void{
    this.showFullscreenPic = !this.showFullscreenPic;
    this.currentFullscreenPic = pictureURL;
  }

  boxDocumentLauncher(arrayIn:string): void {
    this.drilldownService.currentPdfUrl = arrayIn['file'][0]['url'];
    this.drilldownService.boxDataSelectedPDF = this.apiURL + arrayIn['file'][0]['url'];
  }

  openDocument(arrayIn:string): void{
    window.open(this.apiURL + arrayIn['file'][0]['url'],'_blank');
  }

  gdprDisplay(): void {
    this.GDPRdisplayStatus = !this.GDPRdisplayStatus;
  }

  gdprConsent(): void {
    this.contactGDPR = !this.contactGDPR;
  }

  canSubmit(): boolean{
    if(this.contactGDPR && this.recaptchaVerified){
      return false;
    }
    return true;
  }

  resolved(capchtaResponse: string): void{
    this.recaptchaVerified = !this.recaptchaVerified;
  }

  innerLogoDisplay(filterIn:string): boolean {
    if(this.boxData) {
      if(this.boxData['filters']){
        if (this.boxData['filters'].indexOf(filterIn) >= 0) {
          return false
        } else {
          return true
        }
      }
    } else {
      return true
    }
  }

  playVideo(videoURL: string): void{
    this.drilldownService.boxDataVideoURL = videoURL;
  }

  getBoxDataDisplay(){
    return this.drilldownService.boxDataDisplay;
  }

  getVideoPlaying(videoLink){
    return videoLink == this.boxDataVideoURL ? " (playing)" : "";
  }

  sendMail(){
    if(this.validateInput()){
      window.alert('There were errors with your input! Please try again!');
    }else{
      this.mail.initEmail();
      this.mail.sendMail(this.contactFirstName, this.contactSurname, this.contactCompany, this.contactEmail, this.contactPhone, this.contactMessage, this.drilldownService.getBoxDataInterest());
    }
  }

  validateInput(){
    this.setErrorMEssagesToNull();
    let errors = false;
    if(this.contactFirstName == null || this.contactFirstName == ""){
      this.contactFirstNameError = "Please provide a firstname";
      errors = true;
    }
    if(this.contactSurname == null || this.contactSurname == ""){
      this.contactSurnameError = "Please provide a surname";
      errors = true;
    }
    if(this.contactCompany == null || this.contactCompany == ""){
      this.contactCompanyError = "Please provide a company";
      errors = true;
    }
    if(this.contactEmail == null || this.contactEmail == ""){
      this.contactEmailError = "Please provide a valid email address";
      errors = true;
    }
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if(!re.test(this.contactEmail)){
      this.contactEmailError = "Please provide a valid email address";
      errors = true;
    }
    if(this.contactPhone == null || this.contactPhone == ""){
      this.contactPhoneError = "Please provide a valid phone number";
      errors = true;
    }

    if(isNaN(this.contactPhone) && isNaN(parseFloat(this.contactPhone))){
      this.contactPhoneError = "Please provide a valid phone number";
      errors = true;
    }
    return errors;
  }

  setErrorMEssagesToNull(){
    this.contactFirstNameError = null;
    this.contactSurnameError = null;
    this.contactCompanyError = null;
    this.contactMessageError = null;
    this.contactPhoneError = null;
    this.contactEmailError = null;
  }
}
