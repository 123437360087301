import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { AccessoriesService } from './service/accessories.service';
import { LoadingService } from './service/loading.service';
import { RegionPipe } from './region.pipe';

@Injectable({
  providedIn: 'root'
})
export class DrilldownService {

  boxData = null;
  boxDataDisplay = 'intro';
  drilldownOpen = false;

  boxDataTitle          = null;
  boxDataDescription    = null;
  boxDataPDF            = null;
  boxDataInterest       = null;
  boxDataImage          = './../assets/images/na-image.jpg';
  boxDataImages         = [];
  boxDataVideo          = null;
  boxDataVideoURL       = null;
  boxDataSubtitle       = null;
  boxDataSelectedPDF = null;
  boxContentDisplay     = false;
  boxDataLogo = null;
  contactOpen           = false;

  currentPdfUrl = null;

  apiURL                = this.apiService.getAPIURL();

  constructor(private apiService: ApiService, private router: Router, private loadingService: LoadingService,
    private accessoriesService: AccessoriesService, private regionPipe: RegionPipe) { }

  async getDrilldownData(route: string){
    await this.apiService.getMapWithID("1").subscribe((response: any) => {
      let hotspot = response.hotspot.find(x => x.route == route);
      if(this.accessoriesService.Filter == "ROW"){
        this.boxDataLogo = this.apiURL + response['logoImage']['url'];
      }else{
        this.boxDataLogo  = this.apiURL + hotspot['logo']['url']
      }
      //this.boxDataLogo = this.apiURL + response.logoImage.url;

      if(hotspot == undefined){
        this.router.navigate(['dashboard']);
        return;
      }

      this.setDrillDownData(hotspot);
    })
  }

  setDrillDownData(boxData){
    if(this.boxData == "contact"){
      this.boxData = null;
    }else{
      this.boxData              = boxData;
      this.boxDataTitle         = this.boxData['title'];
      this.boxDataDescription   = this.boxData['description'];
      this.boxDataPDF           = this.regionPipe.transform(this.boxData['PDF'], this.accessoriesService.Filter, false);
      this.boxDataVideo         = this.boxData['video'];
      this.boxDataSubtitle      = this.boxData['subtitle'];
      //this.boxDataLogo          = this.apiURL + this.boxData['logo']['url']

      //Reset empty PDF array
      if(this.boxDataPDF.length == 0) {
        this.boxDataPDF = null;
      }else{
        this.boxDocumentLauncher(this.boxDataPDF[0]);
      }
      this.boxDataVideoURL = null;
      if(this.boxDataVideo.length > 0){
        this.boxDataVideoURL = this.boxDataVideo[0]['videoURL'];
      }
      this.boxDataImages = this.boxData['images'];
      if(this.boxData['images'].length > 0) {
        this.boxDataImage = this.apiURL + this.boxData['images'][0]['images']['url'];
      } else {
        this.boxDataImage = './../assets/images/na-image.jpg';
      }
      console.log(this.boxDataImages);
      setTimeout(() => {
        this.loadingService.Loading = false;
      },1000)
    }
  }

  boxDocumentLauncher(arrayIn:string): void {
    this.currentPdfUrl = arrayIn['file']['url'];
    this.boxDataSelectedPDF = this.apiURL + arrayIn['file'][0]['url'];
  }

  getDrilldownOpen(): boolean{
    return this.drilldownOpen;
  }

  setBoxDataDisplay(sectionIn:string): void{
    this.boxDataDisplay = sectionIn;
  }

  getContactOpen(): boolean{
    return this.contactOpen;
  }

  setBoxDataInterest(interest){
    this.boxDataInterest = interest;
  }

  getBoxDataInterest(){
    return this.boxDataInterest;
  }
}
